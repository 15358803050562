import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import allocationDetail from '../assetAllocation/allocationDetail';
import assetPutAwayDetails from './assetPutAwayDetails';

export default {
  name: 'assetPutAway',
  components: {
    appStrings,
    DatePicker,
    allocationDetail,
    assetPutAwayDetails
  },
  props: ['currentLoc','locId','edMode','astNo'],
  watch: {
    currentPage: function() {
      this.getPutAwayAssets();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPutAwayAssets();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      showValueSetModal:false,
      responseMsg: '',
      isSuccess: false,
      currentLocation: {
        text: null,
        value: null
      },
      assetPutAwayData: [
      ],
      assetPutAwayFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1 wid'
        },
        {
          key:'serial_no',
          class:'putAwayCol'
        },
        {
          key: 'doc_num',
          label:'Asset No',
          class:'putAwayCol'
        },
        {
          key: 'description',
        },
        {
          key: 'manufacturer_name',
          label: 'Manufacturer'
        },
        {
          key:'locator',
          class:"d-none"
        },
        {
          key:'locator_id',
          class:"d-none"
        },
        {
          key:'rack_id',
          class:"d-none"
        },
        {
          key:'shelf_id',
          class:"d-none"
        },
        {
          key:'bin_id',
          class:"d-none"
        },
        {
          key: 'asset_allocation'
        },
      ],
      locFlag:false,
      audFlag:false,
      faLocation:{
        text:null,
        value:null
      },
      locator:{
        text:null,
        value:null
      },
      rowData:null,
      showAllocationModal:false,
      rowItem:null,
      specIndex:null,
      faId:null,
      selectAllCheckBox: false,
      selectedRow:[],
      checkFlag:false,
      defaultFlag:null,
      modalHandleFlag:null,
      editMode:false,
      assetAllocation:null,
      selectedItems:{
        selectedLocatorId:null,
        selectedFaLocId:null
      },
        torId:null,
        srNum:null,
        asstNum:null,
        active: false,
        detailsFlag:false,
    };
  },
  mounted() {
    if(this.edMode){
      this.editMode=this.edMode
      this.asstNum=this.astNo
      this.active=true
    }
    if (this.currentLoc) {
      this.locFlag = true;
      this.currentLocation.text = this.currentLoc.text;
        this.currentLocation.value = this.currentLoc.value
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
            // if(this.currentLocation.text && this.faLocation.text){
            // this.showHideAssetAuditModal(true);
            // }else{
            //     alert('Kindly select a location from search parameter')
            // }
              this.editMode=true
            
        }
        else if(actionName === 'save'){
          this.postPutAwayAssets()
        }
     
      }
    });
    this.setDefaultFALocation()
  },
  methods: {
    setDefaultFALocation() {
      const location = commonHelper.getValuesetDataAsset(
        appStrings.VALUESETTYPE.ASSET_LOCATION,
        this.locId
      );
      location.then(key => {
        if (key.length > 0) {
          this.faLocation.text = key[0].value_code;
          this.faLocation.value = key[0].value_set_dtl_id;
        }
        this.getPutAwayAssets()
      });
    },
    getPutAwayAssets(){
      const payload = {
       params:{
          hrLocId:this.locId,
        },
        queryParams:{
          _page: this.currentPage - 1,
          _limit: this.perPage,
          assetFlag:this.active,
          faLocId:this.faLocation.value,
          assetNo:this.astNo?this.astNo:this.asstNum,
          serialNo:this.srNum,
        }

      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetPutAwayDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetPutAwayData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
            this.assetPutAwayData.map(ele=>{
              if(ele.asset_allocation==null){
              ele.asset_allocation="Select Asset Allocation"
              ele.rack_id=null,
              ele.shelf_id=null,
              ele.bin_id=null
              }
            })
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode,flag,index) {
      this.parent_value_set_id = null;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.specIndex = index;
      this.defaultFlag=flag
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        this.parent_value_set_id = this.locId;
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.LOCATOR_VSET){
        this.parent_value_set_id = this.faId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if(this.vsetCode === appStrings.VALUESETTYPE.LOCATION){
        this.currentLocation = {
          value: item.value_set_dtl_id,
          text: item.value_code,
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        this.faLocation={
          text : item.value_code,
          value :item.value_set_dtl_id
        }
        this.faId=item.location_id;
        this.getPutAwayAssets()
      } else if(this.vsetCode === appStrings.VALUESETTYPE.LOCATOR_VSET && this.defaultFlag==false){
        this.assetPutAwayData[this.specIndex].locator=item.locator_name;
        this.assetPutAwayData[this.specIndex].locator_id=item.locator_id;
        this.assetPutAwayData[this.specIndex].asset_allocation="Select Asset Allocation";
        setTimeout(() => {
          this.assetPutAwayData = JSON.parse(
            JSON.stringify(this.assetPutAwayData)
          );
        }, 0);
        this.specIndex=null
      } 
      else if(this.vsetCode === appStrings.VALUESETTYPE.LOCATOR_VSET && this.defaultFlag==true){
        this.locator.text=item.locator_name;
        this.locator.value=item.locator_id;
        this.defaulLocatorId=item.locator_id;
        this.assetAllocation=null;
        this.assetPutAwayData.map(ele=>{
          if(ele.selectBox==true){
            ele.locator=item.locator_name
            ele.locator_id=item.locator_id
            ele.asset_allocation="Select Asset Allocation"
          }
        })
        setTimeout(() => {
          this.assetPutAwayData = JSON.parse(
            JSON.stringify(this.assetPutAwayData)
          );
        }, 0);
        this.specIndex=null
      } 
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode,flag,index) {
      this.specIndex=index
      this.defaultFlag=flag
      this.vsetCode=vsetCode
    if(this.vsetCode === appStrings.VALUESETTYPE.LOCATION){
        this.currentLocation = {
          value: null,
          text: null
        }
      }
      else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        this.faLocation={
          text : null,
          value :null
        }
      }
      else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATOR_VSET && this.defaultFlag==false) {
        this.assetPutAwayData[this.specIndex].locator=null;
        this.assetPutAwayData[this.specIndex].locator_id=null;
        this.assetPutAwayData[this.specIndex].asset_allocation='Select Asset Allocation';
        this.specIndex=null;
        // this.assetAllocation=null

      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.LOCATOR_VSET && this.defaultFlag==true){
        this.locator.text=null;
        this.locator.value=null;
        this.assetAllocation=null;
        this.assetPutAwayData.map(ele=>{
          if(ele.selectBox==true){
            ele.locator=null,
            ele.locator_id=null,
            ele.asset_allocation='Select Asset Allocation'
          }
        })
        setTimeout(() => {
          this.assetPutAwayData = JSON.parse(
            JSON.stringify(this.assetPutAwayData)
          );
        }, 0);
        this.specIndex=null
      } 
    },
    clearData() {
      // this.currentLocation = {
      //   value: null,
      //   text: null
      // };
      this.assetAllocation=null
      this.assetPutAwayData.map(ele=>{
        if(ele.selectBox==true){
          ele.asset_allocation='Select Asset Allocation'
        }
      })
    },
      showHidePutAwayDetailModal(flag,disableFlag, item, index) {
        if(flag==false && disableFlag==true){
          this.showAllocationModal = flag;
          this.modalHandleFlag=false
        }
        else if(flag==true && disableFlag==false){
          if(this.faLocation.text && this.assetPutAwayData[index].selectBox){
          this.detailsFlag=true;
          this.rowData=item;
          this.showAllocationModal = flag;
          this.selectedItems.selectedFaLocId=this.faId?this.faId:this.faLocation.value
          this.selectedItems.selectedLocatorId=item.locator_id;
          if (flag) {
            this.rowItem = item;
            this.specIndex = index;
          }
        }
        else if (this.faLocation.text==null && this.checkFlag){
          this.makeToast('Kindly select FA Location')
        }
        else if (this.faLocation.text==null && !this.checkFlag){
          this.makeToast('Kindly select FA Location')
        }
        else if (this.faLocation.text!==null && !this.assetPutAwayData[index].selectBox && this.editMode){
          this.makeToast('Kindly select a row')
        }
        else{
          this.makeToast('Kindly turn on edit mode')
        }
        }
        else if(flag==true && disableFlag==true){
          if(this.faLocation.text){
            this.detailsFlag=false;
            this.showAllocationModal = flag;
            this.modalHandleFlag=true;
            this.selectedItems.selectedFaLocId=this.faId?this.faId:this.faLocation.value;
            this.selectedItems.selectedLocatorId=this.defaulLocatorId;
          }else{
            this.makeToast('Kindly select FA Location')
          }
       
        }
      },
      makeToast(content) {
        this.$bvToast.toast(content, {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      },
      closeModal(item) {
        if(this.modalHandleFlag){
          this.assetPutAwayData.map(ele=>{
            if(ele.selectBox){
              ele.asset_allocation=item.result
              ele.rack_id=item.items.rack_id
              ele.shelf_id=item.items.shelf_id
              ele.bin_id=item.items.bin_id
            }
          })
          // this.locator={
          //   text:null,
          //   value:null
          // }
          this.assetAllocation=item.result
          // this.selectAllCheckBox=false
        }else{
          this.assetPutAwayData[this.specIndex].asset_allocation = item.result;
          this.assetPutAwayData[this.specIndex].rack_id = item.items.rack_id;
          this.assetPutAwayData[this.specIndex].shelf_id = item.items.shelf_id;
          this.assetPutAwayData[this.specIndex].bin_id = item.items.bin_id;
        }
        this.showAllocationModal = false;
        setTimeout(() => {
          this.assetPutAwayData = JSON.parse(
            JSON.stringify(this.assetPutAwayData)
          );
        }, 0);
      },

      selectAllBoxChecked(flag) {
        this.selectedRow = [];
        if (flag) {
          this.checkFlag = flag;
          this.assetPutAwayData = this.assetPutAwayData.map((data) => {
              data.selectBox = this.selectAllCheckBox;
              // data.locator=null;
              // data.locator_id=null;
              // data.asset_allocation='Select Asset Allocation';
            // if (this.selectAllCheckBox) {
            //   this.selectBoxChecked(data.selectBox, index);
            // }
            return data;
          });
          this.assetAllocation=null;
         this.locator={
            text:null,
            value:null
          };
          this.assetPutAwayData.map(ele=>{
            this.selectedRow.push(ele)
          });
        } else {
          this.checkFlag = flag;
          this.assetPutAwayData = this.assetPutAwayData.map((data) => {
            data.selectBox = this.selectAllCheckBox;
            // if (this.selectAllCheckBox) {
            //   this.selectBoxChecked(data.selectBox, index);
            // }
            return data;
          });
          this.selectedRow = [];
        }
        // if(this.selectAllCheckBox){
        //   this.checkFlag = true;
        // }else{
        //   this.checkFlag = false;
        // }
      },
      selectBoxChecked(flag, index,item,assetNum) {
        this.assetPutAwayData[index].selectBox = flag;
        // this.assetPutAwayData[index].locator=null;
        // this.assetPutAwayData[index].locator_id=null;
        // this.assetPutAwayData[index].asset_allocation='Select Asset Allocation';
        if (flag === true) {
          this.selectedRow.push(item);
        } else {
          this.selectedRow = this.selectedRow.filter(
            el => el.doc_num !== assetNum
          );

        }
        if (this.selectedRow.length > 0 || this.selectAllCheckBox) {
          this.checkFlag = true;
        } else {
          this.checkFlag = false;
        }
      },
      postPutAwayAssets(){
        const payload = {
          asset_allocation_dtl: []
        };
        this.assetPutAwayData.map(ele => {
          if (ele.asset_allocation && ele.selectBox) {
            payload.asset_allocation_dtl.push({
              doc_num: ele.doc_num,  
              locator_id: Number(ele.locator_id),
              rack_id: ele.rack_id,
              shelf_id: ele.shelf_id,
              bin_id: ele.bin_id,
              hr_loc_id: Number(this.currentLocation.value),
              fa_loc_id: Number(this.faLocation.value),
            });
          } 
        });
        if(payload.asset_allocation_dtl.length){
          this.loader = true;
          this.$store
            .dispatch('assets/postAssetPutAwayDetails', payload)
            .then(resp => {
              this.loader = false;
              if (resp.status === 200) {
                this.showAlert = true;
                (this.isSuccess = true);
                (this.responseMsg = resp.data.message);
                this.editMode = false;
                this.totalRows = resp.data.data.total_elements;
                this.checkFlag=false;
                this.assetAllocation=null;
                this.assetPutAwayData.map(ele=>{
                  ele.selectBox=false
                })

                this.selectAllCheckBox=false;
                this.getPutAwayAssets()
              }
              else{
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = resp.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
        else{
          this.makeToast("Asset Allocation can not be null")
        }
       
      },
 
},
beforeDestroy() {
  this.unsubscribe();
}
};
